import { Stack } from '@mui/material';
import { EncounterDetailProps } from '../../type';
import ClinicalExamination from '../../block/ClinicalExamination';
import Diagnosis from '../../block/Diagnosis';
import Solution from '../../block/Solution';
import { EncounterContext } from '../../const';
import React from 'react';

const ExamSession = (props: EncounterDetailProps) => {
  const encounterContext = React.useContext(EncounterContext);
  // When the dialog is open check counter > 0 then call the API get encounter data and reset the counter.
  React.useEffect(() => {
    if (encounterContext?.counter! > 0) {
      encounterContext?.revalidateObservation();
      encounterContext?.revalidateEncounter();
      encounterContext?.setCounter(0);
    }
  }, [encounterContext, props]);
  return (
    <Stack direction="row" alignItems="stretch" gap="10px">
      <ClinicalExamination {...props} />
      <Diagnosis {...props} />
      <Solution {...props} />
    </Stack>
  );
};

export default ExamSession;
