import { Stack } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router';
import { API_SERVER } from '../../common/api';
import { useFetch } from '../../common/hook/useFetch';
import EncounterDetailHeader from 'modules/common/component/Encounter/header';
import EncounterDetailSession from 'modules/common/component/Encounter/EncounterDetailSession';
import { ENCOUNTER_DETAIL_SESSIONS, EncounterContext } from 'modules/common/component/Encounter/const';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import moment from 'moment';
import { AppState } from 'redux/reducer';
import { useSelector } from 'react-redux';

interface Props {}

const PatientEncounterDetailPage: React.FunctionComponent<Props> = (props) => {
  const { encounterId } = useParams<{ encounterId }>();
  const userId = useSelector((state: AppState) => state.authen.user?.user_id);
  const [counter, setCounter] = React.useState<number>(0);
  const { revalidate: revalidateObservation } = useFetch(API_SERVER.observation.getByEncounterId(encounterId), {
    shouldRetryOnError: false,
    enabled: encounterId,
    globalLoading: false,
  });
  const { data: encounter, revalidate: revalidateEncounter } = useFetch(API_SERVER.encounter.get(encounterId), {
    globalLoading: true,
    revalidateOnFocus: false,
  });

  // Readonly khi =>
  // Encouter đã hoàn thành quá 1 ngày
  // Hoặc Encounter ở trạng thái Error
  // Hoặc user hiện tại không phải bác sĩ phụ trách của ca khám
  const readonly = React.useMemo(() => {
    return (
      (encounter?.status === ENCOUNTER_STATUS.FINISHED.value &&
        encounter?.endTime?.isBefore(moment().subtract(1, 'day'))) ||
      encounter?.status === ENCOUNTER_STATUS.ERROR.value ||
      userId !== encounter?.picUserId
    );
  }, [encounter?.endTime, encounter?.picUserId, encounter?.status, userId]);

  if (!encounter) return null;
  return (
    <EncounterContext.Provider
      value={{
        encounter: encounter,
        revalidateObservation: revalidateObservation,
        revalidateEncounter: revalidateEncounter,
        setCounter: setCounter,
        counter: counter,
      }}
    >
      <Stack direction="column" gap="10px" sx={{ backgroundColor: '#E0ECFF' }}>
        <EncounterDetailHeader encounter={encounter} readonly={readonly} />
        <Stack paddingX="16px" direction="column" justifyContent="fle" gap="20px">
          {ENCOUNTER_DETAIL_SESSIONS.map((session, index) => (
            <EncounterDetailSession
              id={session.key}
              readonly={readonly}
              index={index + 1}
              tabs={session.tabs}
              key={session.key}
              encounter={encounter}
            />
          ))}
        </Stack>
      </Stack>
    </EncounterContext.Provider>
  );
};

export default PatientEncounterDetailPage;
