import React from 'react';
import AttachmentSession from './session/attachment';
import ExamSession from './session/exam';
import ExamHistorySession from './session/examHistory';
import GeneralInfoSession from './session/generalInfo';
import IndicationSession from './session/indication';
import MedicationSupplySession from './session/medicalSupply';
import MedicineInUseSession from './session/medicineInUse';
import PrescriptionSession from './session/prescription';
import TherapySession from './session/therapy';
import { EncounterSession } from './type';
import { Encounter } from 'modules/schema';

export const SESSION_KEY = 'session';

export const BLOCK_KEY = 'block';

export const ENCOUNTER_DETAIL_SESSIONS: EncounterSession[] = [
  {
    key: 'generalInfo',
    title: 'encounter.encounterDetail.session.title.generalInfo',
    tabs: [
      { key: 'generalInfo', title: 'encounter.encounterDetail.tab.title.generalInfo', render: GeneralInfoSession },
      {
        key: 'examinationHistory',
        title: 'encounter.encounterDetail.tab.title.historyExam',
        render: ExamHistorySession,
      },
      { key: 'attachments', title: 'encounter.encounterDetail.tab.title.attachments', render: AttachmentSession },
    ],
  },
  {
    key: 'exam',
    title: 'encounter.encounterDetail.session.title.exam',
    tabs: [{ key: 'generalInfo', title: 'encounter.encounterDetail.tab.title.examinal', render: ExamSession }],
  },
  {
    key: 'indication',
    title: 'encounter.encounterDetail.session.title.indication',
    tabs: [
      { key: 'indication', title: 'encounter.encounterDetail.tab.title.indication', render: IndicationSession },
      {
        key: 'medicationUsage',
        title: 'encounter.encounterDetail.tab.title.medicationUsage',
        render: MedicineInUseSession,
      },
      { key: 'supplies', title: 'encounter.encounterDetail.tab.title.supplies', render: MedicationSupplySession },
    ],
  },
  {
    key: 'prescription',
    title: 'encounter.encounterDetail.session.title.prescription',
    tabs: [
      { key: 'prescription', title: 'encounter.encounterDetail.tab.title.prescription', render: PrescriptionSession },
      { key: 'therapy', title: 'encounter.encounterDetail.tab.title.therapy', render: TherapySession },
    ],
  },
];

export const ENCOUNTER_DETAIL_SESSIONS_NURSE: EncounterSession[] = [
  {
    key: 'generalInfo',
    title: 'encounter.encounterDetail.session.title.generalInfo',
    tabs: [
      { key: 'generalInfo', title: 'encounter.encounterDetail.tab.title.generalInfo', render: GeneralInfoSession },
    ],
  },
];

export const EncounterContext = React.createContext<{
  encounter: Encounter;
  revalidateObservation: () => void;
  revalidateEncounter: () => void;
  counter: number;
  setCounter: (number) => void;
} | null>(null);

export const renderLabelSearch = (name, ingredient, dose) => {
  if (ingredient && dose) {
    return `${name} (${ingredient}, ${dose})`;
  } else if (ingredient) {
    return `${name} (${ingredient})`;
  } else if (dose) {
    return `${name} (${dose})`;
  } else {
    return name;
  }
};

export const checkFields = (array, requiredFields: string[]): number[] => {
  let indices: number[] = [];
  array?.forEach((obj, index) => {
    const hasEmptyField = requiredFields.some((field) => {
      const value = obj[field];
      return value === undefined || value === '' || (typeof value === 'number' && value === 0);
    });
    if (hasEmptyField) {
      indices.push(index);
    }
  });
  return indices;
};
